<template>
  <div
    class="table-number-input"
    :class="{
      'tooltip-active': isError && errorMessage,
    }"
  >
    <input
      type="decimal"
      :class="{'is-error': isError, 'transparent': transparent }"
      :value="inputValue"
      :readonly="readonly"
      :placeholder="placeholder"
      @input="(e) => onInput(e.target.value)"
      @change="(e) => onModelChange(e.target.value)"
      @focus="onInputFocus"
      @blur="onInputBlur"
      @keydown="(e) => onKeyDown(e)"
    >
    <div
      v-if="!readonly && hasArrows && !isError"
      class="arrows-root"
    >
      <div
        class="arrow-item"
        @click="onArrowUp"
      >
        <Icon name="plus" />
      </div>
      <div
        class="arrow-item"
        @click="onArrowDown"
      >
        <Icon name="minus" />
      </div>
    </div>
    <Tooltip
      v-if="isError && errorMessage"
      :text="errorMessage"
      class="alert-tooltip"
    >
      <Icon
        class="alert-icon"
        name="alert"
      />
    </Tooltip>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import { debounce } from 'lodash';
import Icon from '@/components/common/Icon';
import Tooltip from '@/components/common/Tooltip';

export default {
  emits: ['update:modelValue', 'onKeyUp', 'onKeyDown', 'onFocus', 'onBlur'],
  components: {
    Icon,
    Tooltip,
  },
  props: {
    modelValue: {
      type: [Number, String, NaN],
      default: null,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    inputDebounceValue: {
      type: Number,
      default: 0,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    isError: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
      required: false,
      default: 100,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    numberOfDecimals: {
      type: Number,
      required: false,
      default: 3,
    },
    acceptNegative: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: [String, Number],
      default: '',
    },
    min: {
      type: Number,
      required: false,
      default: 0,
    },
    hasArrows: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const inputValue = ref(props.modelValue);
    const lastValid = ref(props.modelValue);
    const updateModelValue = (val) => {
      emit('update:modelValue', val?.toString());
    };

    const isValidDecimalLength = (val) => {
      const decimalPart = val.toString().split('.')[1];
      if (decimalPart && decimalPart?.length > props.numberOfDecimals) return false;
      return true;
    };

    const onInput = (val) => {
      let validNumber = new RegExp(/^\d*\.?\d*$/);
      const minValue = props.acceptNegative ? props.min || -100 : props.min;
      if (props.acceptNegative) validNumber = new RegExp(/^-?\d*\.?\d*$/);
      if (
        !validNumber.test(val)
        || (props.max && validNumber.test(val) && val > props.max)
        || (minValue && validNumber.test(val) && val < minValue)
        || (val.includes('.') && !props.numberOfDecimals)
        || !isValidDecimalLength(val)) {
        inputValue.value = null;
        inputValue.value = lastValid.value;
        return;
      }
      lastValid.value = val;
      inputValue.value = val;
    };

    const onModelChange = debounce(() => {
      updateModelValue(inputValue.value);
    }, props.inputDebounceValue);

    const onKeyDown = (e) => {
      if (props.readonly) return;
      const { keyCode } = e;
      if ((keyCode !== 38) && (keyCode !== 40)) return;
      e.preventDefault();
      if (keyCode === 38) emit('onKeyUp');
      else if (keyCode === 40) emit('onKeyDown');
    };

    const onArrowUp = () => emit('onKeyUp');
    const onArrowDown = () => emit('onKeyDown');

    const onInputFocus = () => emit('onFocus');
    const onInputBlur = () => emit('onBlur');

    watch(() => props.modelValue, (newValue) => {
      if (newValue === inputValue.value) return;
      inputValue.value = newValue;
      lastValid.value = newValue;
    });

    return {
      inputValue,
      onInput,
      onModelChange,
      onKeyDown,
      onInputFocus,
      onInputBlur,
      onArrowUp,
      onArrowDown,
    };
  },

};
</script>

<style lang="scss">
.table-number-input {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;

    input {
        display: inline-block;
        width: 100%;
        height: 100%;
        padding: 8px;
        border: $textInputBorder;
        border-radius: $textInputBorderRadius;

        &:focus {
          box-shadow: $textInputFocusBoxShadow;
          outline: none;
        }
        &.is-error {
          border: 1px solid $error500;
        }

        &.transparent {
          border: none;
          outline: none;
          background-color: transparent;
          border-radius: 0;
          &:focus {
            border: 1px solid #a2bfff;
          }
          &.is-error {
            border: 1px solid $error500;
            &:focus {
              box-shadow: unset;
              outline: none;
            }
          }
        }
    }

    .arrows-root {
      position: absolute;
      height: 100%;
      top: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      border: $textInputBorder;
      border-top-right-radius: $textInputBorderRadius;
      border-bottom-right-radius: $textInputBorderRadius;

      .arrow-item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px;
        height: 50%;
        background-color: $white;
        cursor: pointer;
        &:nth-child(1) {
          border-bottom: 1px solid $gray400;
        }
        .icon {
          width: 11px;
          height: 11px;
          svg path {
              stroke: $black;
          }
        }
        &:nth-child(2) {
          svg path {
            stroke: $gray700;
          }
        }
      }
    }

    .alert-tooltip {
      width: 30px;
      height: 100%;
      padding: 0 8px;
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .alert-icon {
        right: 7px;
        svg path {
          stroke: #FF2E2D;
        }
      }
    }

    &.tooltip-active {
      input{
        padding-right: 30px;
      }
    }

    // Hide arrows
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type=number] {
      appearance: textfield;
      -moz-appearance: textfield;
    }
}
</style>
